import styled from 'styled-components';

import theme from '../../../config/theme';

const SIZE = '10px';

const Category = styled.p`
  font-size: ${theme.fonts.sizes.s};
  font-weight: bold;
  line-height: 1.2;
  padding-left: calc(${SIZE} + ${theme.spacing.xs});
  position: relative;

  &::before {
    background-color: ${props => theme.colors[props.color]};
    border-radius: 50%;
    content: '';
    height: ${SIZE};
    left: 0;
    position: absolute;
    top: 3px;
    width: ${SIZE};
  }
`;

export default Category;
