import styled from 'styled-components';
import { Link } from 'react-router-dom';

import theme from '../../../config/theme';

const StyledLink = styled(Link)`
  display: block;

  &:not(:last-child) {
    margin-right: ${theme.spacing.base};
  }
`;

export default StyledLink;
