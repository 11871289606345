import styled from 'styled-components';

import theme from '../../../config/theme';

const Title = styled.h1`
  color: ${theme.colors.white};
  font-size: ${theme.fonts.sizes.base};
  padding-left: calc(${theme.spacing.l} + ${theme.spacing.s});
`;

export default Title;
