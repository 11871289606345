import React, { Component } from 'react';
import mapKeys from 'lodash/mapKeys';
import pickBy from 'lodash/pickBy';
import startsWith from 'lodash/startsWith';
import zipObject from 'lodash/zipObject';
import { format } from 'date-fns';

import Loader from '../../components/Loader';

const withSpreadsheet = WrappedComponent => {
  return class extends Component {
    constructor(props) {
      super(props);

      this.state = {
        loading: true,
        data: null,
        updated: null,
      };
    }

    get url() {
      const { nestedSpreadsheetId, match, sheet } = this.props;
      const id = nestedSpreadsheetId || match.params.id;
      const sheetId = sheet || '1';

      return `https://spreadsheets.google.com/feeds/list/${id}/${sheetId}/public/values?alt=json`;
    }

    getUpdatedTime(results) {
      const updated = results.feed.updated.$t;

      return format(updated, 'MM/DD/YYYY');
    }

    componentDidMount() {
      fetch(this.url)
        .then(response => response.json())
        .then(results => {
          const data = results.feed.entry;
          const updated = this.getUpdatedTime(results);

          this.setState({
            data,
            updated,
          });

          this.normalizeData();
        });
    }

    normalizeData() {
      const { data } = this.state;

      const filteredData = data.map(d => {
        return pickBy(d, (value, key) => {
          return startsWith(key, 'gsx$');
        });
      });

      const normalizedData = filteredData.map(fd => {
        const filteredKeys = mapKeys(fd, (value, key) => key.split('$').pop());
        const keys = Object.keys(filteredKeys);
        const values = Object.keys(fd).map(key => fd[key].$t);

        return zipObject(keys, values);
      });

      this.setState({
        data: normalizedData,
        loading: false,
      });
    }

    render() {
      const { loading, data, updated } = this.state;

      if (loading || data === null) {
        return <Loader />;
      }

      return <WrappedComponent data={data} updated={updated} {...this.props} />;
    }
  };
};

export default withSpreadsheet;
