import React, { Component, Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import upperFirst from 'lodash/upperFirst';

import { setTitle } from '../../lib/utils';

import Branded from '../../layouts/Branded';

import Loader from '../../components/Loader';
import withSpreadsheet from '../../components/withSpreadsheet';

import Category from './styled/Category';
import Checkbox from './styled/Checkbox';
import Clear from './styled/Clear';
import Container from './styled/Container';
import Filter from './styled/Filter';
import Label from './styled/Label';
import SkillList from './styled/SkillList';
import SkillListContainer from './styled/SkillListContainer';
import SkillButton from './styled/SkillButton';
import TeamList from './styled/TeamList';
import TeamMemberButton from './styled/TeamMemberButton';

class Skills extends Component {
  state = {
    data: null,
    skills: [],
    selectedTeamMember: null,
    selectedTeamMemberSkillSet: {},
    selectedSkills: new Set(),
    selectedSkillTeamMembers: [],
    selectedCategories: new Set(),
  };

  skillsMap = {
    amazonaws: 'Amazon AWS',
    c: 'C++',
    c_2: 'C#',
    cidelivery: 'CI & Delivery',
    designsystems: 'Design Systems',
    devops: 'Dev Ops',
    ecommerce: 'E-commerce',
    gamedevelopment: 'Game Development',
    graphql: 'GraphQL',
    htmlcss: 'HTML & CSS',
    iot: 'IoT',
    machinelearning: 'Machine Learning',
    mongodb: 'MongoDB',
    nativeandroid: 'Native Android',
    nativeios: 'Native iOS',
    reactnative: 'React Native',
    rubyonrails: 'Ruby on Rails',
    sql: 'SQL',
  };

  categories = {
    1: {
      key: '5',
      value: "I've used it a lot and want to use it again",
      color: 'green',
    },
    2: {
      key: '4',
      value: "I've used it a little and want to learn more",
      color: 'green',
    },
    3: {
      key: '3',
      value: "I haven't used it before and want to learn it",
      color: 'yellow',
    },
    4: {
      key: '2',
      value: "I don't want to use it again",
      color: 'red',
    },
    5: {
      key: '1',
      value: 'I have no interest in using it',
      color: 'red',
    },
  };

  componentDidMount() {
    this.normalizeData();
  }

  normalizeData() {
    const { data } = this.props;
    const skills = this.collectSkills();
    const teamMemberSkillSet = data.map(item => ({
      member: item.teammember,
      skillSet: this.getTeamMemberSkillSet(item.teammember),
    }));

    this.setState({
      skills,
      data: teamMemberSkillSet,
      selectedCategories: this.setDefaultCategories(),
    });
  }

  collectSkills() {
    const { data } = this.props;
    const skills = this.getTeamMemberSkills(data[0]);

    return this.formatSkills(skills);
  }

  formatSkills(skills) {
    return skills.map(this.formatSkill);
  }

  formatSkill = skill =>
    this.skillsMap[skill] ? this.skillsMap[skill] : upperFirst(skill);

  setDefaultCategories() {
    const { selectedCategories } = this.state;

    const defaultSelectedCategories = new Set(selectedCategories);

    defaultSelectedCategories.add(this.categories[1].key);
    defaultSelectedCategories.add(this.categories[2].key);
    defaultSelectedCategories.add(this.categories[3].key);

    return defaultSelectedCategories;
  }

  getTeamMemberSkills(memberObject) {
    return Object.keys(memberObject).filter(k => k !== 'teammember');
  }

  getTeamMemberSkillSet(member) {
    const { data } = this.props;
    const foundTeamMember = data.find(item => item.teammember === member);
    const teamMemberSkills = this.getTeamMemberSkills(foundTeamMember);
    const totalCategories = Object.keys(this.categories).length;
    let skills = {};

    for (let i = 1; i <= totalCategories; i++) {
      skills[this.categories[i].key] = [];
    }

    teamMemberSkills.map(skill => {
      for (let i = 1; i <= totalCategories; i++) {
        if (foundTeamMember[skill] === this.categories[i].value) {
          return skills[this.categories[i].key].push(this.formatSkill(skill));
        }
      }

      return null;
    });

    return skills;
  }

  handleTeamMemberClick = (event, member) => {
    event.preventDefault();

    const { data } = this.state;
    const teamMember = data.find(item => item.member === member);

    this.setState({
      selectedTeamMember: member,
      selectedTeamMemberSkillSet: teamMember.skillSet,
    });
  };

  handleSkillClick = (event = null, skill = null) => {
    event && event.preventDefault();

    const { data, selectedSkills, selectedCategories } = this.state;
    let newSelectedSkills = new Set(selectedSkills);

    if (skill) {
      if (selectedSkills.has(skill)) {
        newSelectedSkills.delete(skill);
      } else {
        newSelectedSkills.add(skill);
      }
    }

    const selectedSkillTeamMembers = data
      .map(item => {
        const applicableSkills = flatten(
          [...selectedCategories].map(category => item.skillSet[category])
        );

        if (
          [...newSelectedSkills].every(newSelectedSkill =>
            applicableSkills.includes(newSelectedSkill)
          )
        ) {
          return item.member;
        }

        return null;
      })
      .filter(Boolean);

    this.setState({
      selectedSkills: newSelectedSkills,
      selectedTeamMember: null,
      selectedSkillTeamMembers,
      selectedTeamMemberSkillSet: {},
    });
  };

  handleSkillFilterChange = (event, key) => {
    const { selectedCategories } = this.state;
    const newSelectedCategories = new Set(selectedCategories);

    if (selectedCategories.has(key)) {
      newSelectedCategories.delete(key);
    } else {
      newSelectedCategories.add(key);
    }

    this.setState(
      {
        selectedCategories: newSelectedCategories,
      },
      () => this.handleSkillClick()
    );
  };

  handleClearClick = event => {
    event.preventDefault();

    this.setState({
      selectedSkills: new Set(),
      selectedTeamMemberSkillSet: {},
      selectedTeamMember: null,
      selectedCategories: this.setDefaultCategories(),
    });
  };

  renderSkillFilter() {
    const { selectedCategories } = this.state;

    return (
      <Filter>
        {Object.values(this.categories)
          .filter(c => c.color !== 'red')
          .map((category, index) => (
            <div key={index}>
              <Checkbox
                type="checkbox"
                id={category.key}
                checked={selectedCategories.has(category.key)}
                onChange={event =>
                  this.handleSkillFilterChange(event, category.key)
                }
              />
              <Label htmlFor={category.key}>{category.value}</Label>
            </div>
          ))}
        <div>
          <Clear onClick={this.handleClearClick}>Clear the filter</Clear>
        </div>
      </Filter>
    );
  }

  renderTeamMemberFilter() {
    return (
      <Filter>
        {Object.values(this.categories).map((category, index) => (
          <Category key={index} color={category.color}>
            {category.value}
          </Category>
        ))}
        <div>
          <Clear onClick={this.handleClearClick}>Clear the filter</Clear>
        </div>
      </Filter>
    );
  }

  renderTeamMembers() {
    const {
      data,
      selectedSkills,
      selectedTeamMember,
      selectedSkillTeamMembers,
    } = this.state;

    const isTeamMembersEmpty = selectedSkillTeamMembers.length === 0;
    const isDataEmpty = data.length === 0;
    const emptyContent = (
      <Fragment>
        <p>
          Nobody wants to deal with this. Burn it with fire!{' '}
          <span role="img" aria-label="fire">
            🔥
          </span>
        </p>
        <p>
          Or maybe nobody has answered for this technology yet&hellip;{' '}
          <span role="img" aria-label="grimacing">
            😬
          </span>
        </p>
      </Fragment>
    );

    if (!isEmpty(selectedSkills)) {
      return isTeamMembersEmpty
        ? emptyContent
        : selectedSkillTeamMembers.map((member, index) => (
            <li key={index}>
              <TeamMemberButton
                isActive={selectedTeamMember === member}
                onClick={event => this.handleTeamMemberClick(event, member)}
              >
                {member}
              </TeamMemberButton>
            </li>
          ));
    }

    return isDataEmpty
      ? emptyContent
      : data.map((item, index) => (
          <li key={index}>
            <TeamMemberButton
              isActive={selectedTeamMember === item.member}
              onClick={event => this.handleTeamMemberClick(event, item.member)}
            >
              {item.member}
            </TeamMemberButton>
          </li>
        ));
  }

  renderSkills() {
    const { skills, selectedSkills, selectedTeamMemberSkillSet } = this.state;

    return (
      <SkillList>
        {skills.map((skill, index) => (
          <li key={index}>
            <SkillButton
              isInactive={
                !isEmpty(selectedSkills) && ![...selectedSkills].includes(skill)
              }
              skill={skill}
              skills={
                !isEmpty(selectedTeamMemberSkillSet)
                  ? selectedTeamMemberSkillSet
                  : false
              }
              categories={this.categories}
              onClick={event => this.handleSkillClick(event, skill)}
            >
              {skill}
            </SkillButton>
          </li>
        ))}
      </SkillList>
    );
  }

  renderFilter() {
    const { selectedSkills, selectedTeamMember } = this.state;

    if (!isEmpty(selectedSkills)) {
      return this.renderSkillFilter();
    }

    if (selectedTeamMember && isEmpty(selectedSkills)) {
      return this.renderTeamMemberFilter();
    }
  }

  render() {
    const { title, match, updated } = this.props;
    const { data } = this.state;

    if (data === null) {
      return <Loader />;
    }

    return (
      <DocumentTitle title={setTitle({ match, title })}>
        <Branded verticalScroll updated={updated}>
          <Container>
            <TeamList>{this.renderTeamMembers()}</TeamList>
            <SkillListContainer>
              {this.renderFilter()}
              {this.renderSkills()}
            </SkillListContainer>
          </Container>
        </Branded>
      </DocumentTitle>
    );
  }
}

export default withSpreadsheet(Skills);
