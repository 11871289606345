import styled from 'styled-components';

import theme from '../../../config/theme';

const active = ({ isActive }) => {
  if (isActive) {
    return `
      background-color: ${theme.colors.black};
      color: ${theme.colors.white};
    `;
  }
};

const TeamMemberButton = styled.button`
  border: 0;
  border-radius: ${theme.radius.base};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  display: block;
  font-family: ${theme.fonts.family};
  font-size: ${theme.fonts.sizes.base};
  font-weight: bold;
  padding: ${theme.spacing.s} ${theme.spacing.base};
  text-align: left;
  width: 100%;

  &:focus {
    outline: none;
  }

  ${active};
`;

export default TeamMemberButton;
