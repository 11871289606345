import styled from 'styled-components';

const Container = styled.div`
  align-items: center;
  display: flex;
  font-size: 50px;
  justify-content: center;
  min-height: 100vh;
`;

export default Container;
