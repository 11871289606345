import styled from 'styled-components';

import theme from '../../../config/theme';

const Placeholder = styled.div`
  background-color: ${theme.colors.lightGray};
  height: ${props => props.height}px;
  width: ${props => props.width}px;
`;

export default Placeholder;
