import styled from 'styled-components';

import theme from '../../../config/theme';

const Filter = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${theme.spacing.base};

  > *:not(:last-child) {
    margin-bottom: ${theme.spacing.base};
  }
`;

export default Filter;
