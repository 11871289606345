import React, { Fragment } from 'react';
import { Route, withRouter } from 'react-router-dom';

import { getProduct } from '../../config/products';

const renderRoutes = routes => {
  return routes.map((route, index) => {
    const sheet = route.sheet ? route.sheet : (index + 1).toString();

    return (
      <Route
        key={index}
        exact
        path={route.path}
        render={props => (
          <route.component {...props} {...route} sheet={sheet} />
        )}
      />
    );
  });
};

const Routes = props => {
  const id = props.location.pathname.split('/')[1];
  const product = getProduct(id);

  if (product) {
    const routes = getProduct(id).routes;

    return <Fragment>{renderRoutes(routes)}</Fragment>;
  }

  return null;
};

export default withRouter(Routes);
