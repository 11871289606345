import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Root from '../../screens/Root';

import Routes from '../Routes';

const App = () => (
  <Router>
    <Switch>
      <Route exact path="/" component={Root} />
      <Routes />
      <Route render={() => <Root emoji='😬' />} />
    </Switch>
  </Router>
);

export default App;
