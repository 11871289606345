// @flow

import React, { Component } from 'react';

import Placeholder from './styled/Placeholder';
import StyledImage from './styled/Image';

class Image extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
    };
  }

  handleLoad = () => {
    const { onLoaded } = this.props;
    const { width, height } = this.node;

    this.setState(
      {
        isLoaded: true,
      },
      onLoaded && onLoaded({ width, height })
    );
  };

  render() {
    const { isLoaded } = this.state;
    const { src, alt, height, width, ...props } = this.props;

    return (
      <div>
        {isLoaded ? null : <Placeholder />}
        <StyledImage
          ref={node => {
            this.node = node;
          }}
          src={src}
          alt={alt}
          height={height}
          width={width}
          onLoad={this.handleLoad}
          active={isLoaded}
          {...props}
        />
      </div>
    );
  }
}

export default Image;
