import styled from 'styled-components';

import Image from '../../../components/Image';

import theme from '../../../config/theme';

const Logo = styled(Image)`
  margin-bottom: ${theme.spacing.l};
  margin-left: auto;
  margin-right: auto;
`;

export default Logo;
