import styled from 'styled-components';

import theme from '../../../../config/theme';

const MetaItem = styled.li`
  color: ${theme.colors.mediumGray};
  font-size: ${theme.fonts.sizes.s};
  line-height: 1.3;

  &:not(:last-child) {
    margin-right: ${theme.spacing.s};

    &::after {
      content: '/';
      padding-left: ${theme.spacing.s};
    }
  }
`;

export default MetaItem;
