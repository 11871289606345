import styled from 'styled-components';

import theme from '../../../config/theme';

const Card = styled.div`
  padding: ${theme.spacing.base};
  padding-top: calc(${theme.spacing.l} + ${theme.spacing.s});
  position: relative;
`;

export default Card;
