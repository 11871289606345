import React from 'react';

import Container from './styled/Container';

const Root = ({ emoji = '👀' }) => (
  <Container>
    <span role="img" aria-label="Nothing to see here.">
      {emoji}
    </span>
  </Container>
);

export default Root;
