import styled from 'styled-components';

import theme from '../../../config/theme';

const Updated = styled.p`
  font-size: ${theme.fonts.sizes.s};
  display: none;
  position: absolute;
  right: ${theme.spacing.base};
  top: 50%;
  transform: translateY(-50%);

  @media screen and (min-width: ${theme.breakpoints.m}) {
    display: block;
  }
`;

export default Updated;
