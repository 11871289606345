import styled from 'styled-components';

import theme from '../../../config/theme';

const COLUMN_HEIGHT = 'calc(100vh - 135px)';
const COLUMN_HEIGHT_FULL = 'calc(100vh - 125px)';
const COLUMN_WIDTH = '450px';

const setScrollbar = ({ hasScrollbar }) => {
  if (hasScrollbar) {
    return `
      height: calc(100vh - 105px);

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        height: ${theme.spacing.s};
      }

      &::-webkit-scrollbar-thumb {
        background-color: ${theme.colors.mediumGray};
        border-radius: 100px;
      }
    `;
  }

  return `height: 100vh;`;
};

const Categories = styled.div`
  margin-left: ${theme.spacing.s};
  margin-right: ${theme.spacing.s};

  @media screen and (min-width: ${theme.breakpoints.m}) {
    align-items: start;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;

    ${props => setScrollbar(props)};
  }

  > * {
    display: flex;
    flex: 0 0 ${COLUMN_WIDTH};
    margin: ${theme.spacing.s};
    margin-bottom: ${theme.spacing.base};
    margin-top: ${theme.spacing.base};

    @media screen and (min-width: ${theme.breakpoints.m}) {
      margin: ${theme.spacing.s};
      max-height: ${props =>
        props.hasScrollbar ? COLUMN_HEIGHT : COLUMN_HEIGHT_FULL};
    }
  }
`;

export default Categories;
