import styled from 'styled-components';

import theme from '../../../../config/theme';
import { getStatusColor } from '../../../../lib/styleUtils';

const DOT_SIZE = 15;
const RECT_SIZE = 50;

const Container = styled.li`
  font-size: ${theme.fonts.sizes.s};
  position: relative;
  padding-bottom: ${theme.spacing.s};
  padding-left: calc(${theme.spacing.l} + ${theme.spacing.base});
  padding-right: ${theme.spacing.base};
  padding-top: ${theme.spacing.s};

  &::after {
    background-color: ${props =>
      getStatusColor(props.status, props.labels, theme.statusColors.neutral)};
    border-radius: 50%;
    content: '';
    display: block;
    height: ${DOT_SIZE}px;
    left: ${RECT_SIZE / 2}px;
    top: 50%;
    transform: translateY(-50%);
    margin-left: -${DOT_SIZE / 2}px;
    position: absolute;
    width: ${DOT_SIZE}px;
  }

  &::before {
    background-color: ${theme.colors.lightGray};
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: ${RECT_SIZE}px;
  }

  &:first-child {
    padding-top: ${theme.spacing.base};

    &::after {
      margin-top: ${theme.spacing.xs};
    }
  }

  &:last-child {
    padding-bottom: ${theme.spacing.base};

    &::after {
      margin-top: ${props =>
        props.totalItems > 1 ? `-${theme.spacing.xs}` : 0};
    }

    &::before {
      background: linear-gradient(${theme.colors.lightGray}, white);
    }
  }
`;

export default Container;
