import styled from 'styled-components';

import { listReset } from '../../../lib/styleUtils';
import theme from '../../../config/theme';

const SkillList = styled.div`
  ${listReset()};

  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;

  > *:not(:last-child) {
    margin-bottom: ${theme.spacing.xs};
    margin-right: ${theme.spacing.xs};
  }
`;

export default SkillList;
