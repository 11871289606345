import styled, { keyframes } from 'styled-components';

import theme from '../../../config/theme';

const rotate = keyframes`
  to {
    transform: rotate(1turn);
  }
`;

const Icon = styled.svg`
  animation: ${rotate} 2s infinite;
  margin-right: ${theme.spacing.s};
`;

export default Icon;
