import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import DocumentTitle from 'react-document-title';

import { setTitle } from '../../lib/utils';
import { getProduct } from '../../config/products';

import withSpreadsheet from '../../components/withSpreadsheet';

import Card from './styled/Card';
import Container from './styled/Container';
import ContentContainer from './styled/ContentContainer';
import Label from './styled/Label';
import Link from './styled/Link';
import Logo from './styled/Logo';
import Markdown from './styled/Markdown';
import Views from './styled/Views';

import diLogo from '../../images/logo-di.svg';

class Dashboard extends Component {
  renderDataContent({ key, value }) {
    switch (key) {
      case 'Client': {
        return <h1>{value}</h1>;
      }
      case 'Project': {
        return <h2>{value}</h2>;
      }
      default: {
        return (
          <Markdown>
            <ReactMarkdown source={value} />
          </Markdown>
        );
      }
    }
  }

  renderData() {
    const { data } = this.props;

    return data.map((d, index) => (
      <Card key={index}>
        <Label>{d.key}</Label>
        {this.renderDataContent(d)}
      </Card>
    ));
  }

  renderViews() {
    const { match } = this.props;
    const product = getProduct(match.params.id);

    return product.routes
      .filter(route => !route.hideLink)
      .map((route, index) => {
        if (index !== 0) {
          const re = new RegExp(/\/:id\/(.*)/);
          const path = route.path.match(re)[1];

          return (
            <Link key={index} to={`/${match.params.id}/${path}`}>
              {route.title} &raquo;
            </Link>
          );
        }

        return null;
      });
  }

  render() {
    const { title, match, updated } = this.props;

    return (
      <DocumentTitle title={setTitle({ match, title })}>
        <Container>
          <Logo src={diLogo} width="400" height="98" />

          <ContentContainer>
            {this.renderData()}
            <Card>
              <Label>Views</Label>
              <Views>{this.renderViews()}</Views>
            </Card>
            <Card>
              <Label>Last Updated</Label>
              <Views>{updated}</Views>
            </Card>
          </ContentContainer>
        </Container>
      </DocumentTitle>
    );
  }
}

export default withSpreadsheet(Dashboard);
