import styled from 'styled-components';

import { listReset } from '../../../lib/styleUtils';
import theme from '../../../config/theme';

const TeamList = styled.div`
  ${listReset()};

  flex-basis: 20%;
  margin-right: ${theme.spacing.base};

  > *:not(:last-child) {
    margin-bottom: ${theme.spacing.xs};
  }
`;

export default TeamList;
