import Dashboard from '../screens/Dashboard';
import Skills from '../screens/Skills';
import Status from '../screens/Status';

const products = [
  {
    key: '1kwoHL2j_r0hmk-WTdCi5K0iI9nyrATM_5GxMU-3dG98',
    client: 'Internal',
    project: 'Template',
    routes: [
      { path: '/:id', component: Dashboard, title: 'Dashboard' },
      { path: '/:id/roadmap', component: Status, title: 'Roadmap' },
    ],
    status: {
      colors: {
        green: 'Complete',
        yellow: 'In Progress',
        red: 'Blocked',
        neutral: 'Not Started',
      },
    },
  },
  {
    key: '1Yn4SVSmIQWt9bfEe3nm1E4BxMI29dtNkaORrbtTVjy0',
    client: 'Internal',
    project: 'Resourcing',
    routes: [
      { path: '/:id', component: Dashboard, title: 'Dashboard' },
      { path: '/:id/projects', component: Status, title: 'Projects' },
      { path: '/:id/team', component: Status, title: 'Team' },
      {
        path: '/:id/skills',
        component: Skills,
        title: 'Skill Inventory',
        hideLink: true,
      },
    ],
    status: {
      options: {
        ignoreProgress: true,
        showCategoryCount: true,
      },
      colors: {
        green: 'On Track',
        yellow: 'Concerned',
        red: 'Off Track',
        team: {
          green: 'Not Moving',
          yellow: 'Can Move',
          red: 'Will Move',
          neutral: 'Unsure',
        },
      },
      additionalFields: ['role'],
    },
  },
  {
    key: '1ulQH_FZEvpQvXORlJ1BODm2ngLjbC3cA2n9IGjxxdvg',
    client: 'Apollos',
    project: '',
    routes: [
      { path: '/:id', component: Dashboard, title: 'Dashboard' },
      { path: '/:id/roadmap', component: Status, title: 'Roadmap' },
    ],
    status: {
      options: {
        useInternalLogo: true,
      },
      colors: {
        green: 'Complete',
        yellow: 'In Progress',
        red: 'Blocked',
        neutral: 'Not Started',
      },
    },
  },
  {
    key: '1vzjovsRy2fczgzf84yuITgaOWefrwmSVZFFtzV88tBQ',
    client: 'Big Ass Fans',
    project: 'SpecLab & TSR App',
    routes: [
      { path: '/:id', component: Dashboard, title: 'Dashboard' },
      {
        path: '/:id/roadmap/v1',
        component: Status,
        title: 'Roadmap v1',
        categoryOrder: ['Near', 'Now', 'Released'],
        hideLink: true,
      },
      {
        path: '/:id/roadmap/v2',
        component: Status,
        title: 'Roadmap v2',
        categoryOrder: ['Near', 'Now', 'Released'],
        hideLink: true,
      },
      {
        path: '/:id/roadmap/v3',
        component: Status,
        title: 'Roadmap v3',
        categoryOrder: ['Now', 'Near', 'Released'],
      },
      {
        path: '/:id/roadmap',
        component: Status,
        title: 'Roadmap v4',
        categoryOrder: ['Now', 'Near', 'Future'],
      },
    ],
    status: {
      options: {
        categoryOrder: ['Now', 'Near', 'Future', 'Released'],
        showCategoryCount: true,
      },
      colors: {
        green: 'Complete',
        yellow: 'In Progress',
        red: 'Blocked',
        neutral: 'Not Started',
      },
      additionalFields: ['flag', 'description'],
    },
  },
  {
    key: '15mS1my5Jn4cbNlVqr-TCn6iUEkdz2CgG1trnTU6M_6Q',
    client: 'Crossroads',
    project: '',
    routes: [
      { path: '/:id', component: Dashboard, title: 'Dashboard' },
      { path: '/:id/roadmap', component: Status, title: 'Roadmap' },
    ],
    status: {
      options: {
        useInternalLogo: true,
      },
      colors: {
        green: 'Complete',
        yellow: 'In Progress',
        red: 'Blocked',
        neutral: 'Not Started',
      },
    },
  },
  {
    key: '1QtDiTR47xE2BYYSoGKnLwQxb2RHbBXlrKIMJdAjEvXA',
    client: 'NewSpring',
    project: '',
    routes: [
      { path: '/:id', component: Dashboard, title: 'Dashboard' },
      { path: '/:id/roadmap', component: Status, title: 'Roadmap' },
    ],
    status: {
      options: {
        useInternalLogo: true,
      },
      colors: {
        green: 'Complete',
        yellow: 'In Progress',
        red: 'Blocked',
        neutral: 'Not Started',
      },
    },
  },
  {
    key: '1S_SG7dRL1vdHx3oIMQpgQx0Ev3oVplNyxjXlXrfa2Lk',
    client: 'Willow Creek',
    project: '',
    routes: [
      { path: '/:id', component: Dashboard, title: 'Dashboard' },
      { path: '/:id/roadmap', component: Status, title: 'Roadmap' },
    ],
    status: {
      options: {
        useInternalLogo: true,
      },
      colors: {
        green: 'Complete',
        yellow: 'In Progress',
        red: 'Blocked',
        neutral: 'Not Started',
      },
    },
  },
  {
    key: '1cfAk1zQvLPhMSb-sdYaLO5oBt_kEKSJ2-lgQ4-ueiUU',
    client: 'CVG',
    project: 'TPS',
    routes: [
      { path: '/:id', component: Dashboard, title: 'Dashboard' },
      { path: '/:id/roadmap', component: Status, title: 'Roadmap' },
    ],
    status: {
      options: {
        logoHeight: '30',
        showCategoryCount: true,
      },
      colors: {
        green: 'Complete',
        yellow: 'In Progress',
        red: 'Blocked',
        neutral: 'Not Started',
      },
      additionalFields: ['objective'],
    },
  },
  {
    key: '13d4VDcu0A9suvM2E2ICLGUXhlqNqLPE6t7s5a3NSwA4',
    client: 'Christ Fellowship',
    project: '',
    routes: [
      { path: '/:id', component: Dashboard, title: 'Dashboard' },
      { path: '/:id/roadmap', component: Status, title: 'Roadmap' },
    ],
    status: {
      options: {
        logoHeight: '20',
        showCategoryCount: true,
      },
      colors: {
        green: 'Complete',
        yellow: 'In Progress',
        red: 'Blocked',
        neutral: 'Not Started',
      },
      additionalFields: ['objective'],
    },
  },
  {
    key: '1Gk70slL15eZFZ1-beKDii3pZ9zZ_lsyul1aAKRemT-c',
    client: 'Differential',
    project: 'differential.com',
    routes: [
      { path: '/:id', component: Dashboard, title: 'Dashboard' },
      { path: '/:id/roadmap', component: Status, title: 'Roadmap' },
    ],
    status: {
      options: {
        useInternalLogo: true,
        showCategoryCount: true,
      },
      colors: {
        green: 'Complete',
        yellow: 'In Progress',
        red: 'Blocked',
        neutral: 'Not Started',
      },
      additionalFields: ['objective'],
    },
  },
  {
    key: '11t7YQDLl75WkcdVczSQQW_eEteuYJl9YG8GCflPysWs',
    client: 'North Point',
    project: '',
    routes: [
      { path: '/:id', component: Dashboard, title: 'Dashboard' },
      { path: '/:id/roadmap', component: Status, title: 'Roadmap' },
    ],
    status: {
      options: {
        showCategoryCount: true,
      },
      colors: {
        green: 'Complete',
        yellow: 'In Progress',
        red: 'Blocked',
        neutral: 'Not Started',
      },
      additionalFields: ['objective'],
    },
  },
  {
    key: '1tYohnqaddvDzsTmK8vW4SHOzvTqlAjeYwA-xcQP8fO8',
    client: 'Apollos Core',
    project: '',
    routes: [
      { path: '/:id', component: Dashboard, title: 'Dashboard' },
      { path: '/:id/roadmap', component: Status, title: 'Roadmap' },
    ],
    status: {
      options: {
        showCategoryCount: true,
      },
      colors: {
        green: 'Complete',
        yellow: 'In Progress',
        red: 'Blocked',
        neutral: 'Not Started',
      },
      additionalFields: ['objective'],
    },
  },
];

export const getProduct = key => products.find(product => product.key === key);

export default products;
