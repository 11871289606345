import styled from 'styled-components';

import theme from '../../../config/theme';

const Markdown = styled.div`
  p:not(:last-child) {
    margin-bottom: ${theme.spacing.base};
  }

  ul,
  ol {
    margin-left: ${theme.spacing.l};
    margin-left: ${theme.spacing.l};
    padding: 0;
  }

  li {
    margin-bottom: ${theme.spacing.s};
  }
`;

export default Markdown;
