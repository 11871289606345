import React from 'react';

import StatusItem from './StatusItem';

import Container from './styled/Container';
import Content from './styled/Content';
import Footer from './styled/Footer';
import Header from './styled/Header';
import List from './styled/List';
import ProgressBar from './styled/ProgressBar';
import Title from './styled/Title';

const StatusCard = ({
  items,
  category,
  status,
  percentage,
  labels,
  ...rest
}) => (
  <Container>
    <Header status={status} labels={labels} count={rest.count && items.length}>
      <Title>{category}</Title>
    </Header>
    <Content>
      <List>
        {items.map((item, index) => (
          <StatusItem
            key={index}
            labels={labels}
            totalItems={items.length}
            {...item}
            {...rest}
          />
        ))}
      </List>
    </Content>
    {percentage !== null && (
      <Footer>
        <ProgressBar
          status={status}
          labels={labels}
          style={{ width: `${percentage}%` }}
        />
      </Footer>
    )}
  </Container>
);

export default StatusCard;
