import styled from 'styled-components';
import { Tooltip } from 'react-tippy';

import theme from '../../../../config/theme';

import 'react-tippy/dist/tippy.css';

const Title = styled(Tooltip)`
  font-size: ${theme.fonts.sizes.base};

  &:active,
  &:focus,
  &:hover {
    cursor: default;
  }
`;

export default Title;
