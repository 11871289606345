import React, { Component } from 'react';
import every from 'lodash/every';
import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import some from 'lodash/some';
import sortBy from 'lodash/sortBy';
import DocumentTitle from 'react-document-title';

import { getProduct } from '../../config/products';
import { setTitle } from '../../lib/utils';

import Branded from '../../layouts/Branded';

import Loader from '../../components/Loader';
import StatusCard from '../../components/StatusCard';
import withSpreadsheet from '../../components/withSpreadsheet';

import Categories from './styled/Categories';

class Status extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      categories: null,
      hasScrollbar: false,
    };

    this.categoriesNode = null;

    this.setCategoriesRef = node => {
      this.categoriesNode = node;
    };
  }

  componentDidMount() {
    this.normalizeData();

    window.addEventListener('resize', this.checkForScrollbar.bind(this));
  }

  componentDidUpdate() {
    if (this.state.hasScrollbar) {
      return;
    }

    this.categoriesNode && this.checkForScrollbar();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkForScrollbar.bind(this));
  }

  checkForScrollbar(event = {}) {
    if (this.categoriesNode.scrollWidth > window.innerWidth) {
      this.setState({
        hasScrollbar: true,
      });
    } else {
      if (event && event.type === 'resize') {
        this.setState({
          hasScrollbar: false,
        });
      }
    }
  }

  get currentProduct() {
    const { match } = this.props;
    const product = getProduct(match.params.id);

    return product;
  }

  getRouteValue(key, value) {
    return this.currentProduct.routes.find(p => p[key] === value);
  }

  getCategoryOrder() {
    const { match } = this.props;
    const categoryOrderDefault = get(
      this.currentProduct,
      'status.options.categoryOrder'
    );
    const currentProductRoute = this.getRouteValue('path', match.path);

    return currentProductRoute.categoryOrder || categoryOrderDefault || null;
  }

  normalizeData() {
    const { data } = this.props;
    const categoryOrder = this.getCategoryOrder();
    let sortedData = data;

    if (categoryOrder) {
      sortedData = sortBy(data, d => categoryOrder.indexOf(d.category));
    }

    const groupedData = groupBy(sortedData, 'category');
    const categories = Object.keys(groupedData).map(category => ({
      category,
      status: this.setCategoryStatus(groupedData[category]),
    }));

    this.setState({
      data: groupedData,
      categories,
    });
  }

  setCategoryStatus(set) {
    const { match } = this.props;
    const product = getProduct(match.params.id);

    if (every(set, { status: product.status.colors.red })) {
      return product.status.colors.red;
    }

    if (every(set, { status: product.status.colors.yellow })) {
      return product.status.colors.yellow;
    }

    if (every(set, { status: product.status.colors.green })) {
      return product.status.colors.green;
    }

    if (
      some(set, { status: product.status.colors.yellow }) ||
      some(set, { status: product.status.colors.green })
    ) {
      return product.status.colors.yellow;
    }

    return product.status.colors.neutral;
  }

  setCompletionPercentage(set, status) {
    const { match } = this.props;
    const product = getProduct(match.params.id);
    const numberCompleted = filter(set, {
      status: product.status.colors.green,
    }).length;

    switch (status) {
      case product.status.colors.neutral: {
        return '0';
      }
      case product.status.colors.green: {
        return '100';
      }
      case product.status.colors.yellow: {
        return (numberCompleted / set.length) * 100;
      }
      default: {
        return '0';
      }
    }
  }

  renderCategories(rawData) {
    const { data, categories } = this.state;
    const { match, title } = this.props;
    const product = getProduct(match.params.id);
    const labels = get(
      product.status,
      `colors.${title.toLowerCase()}`,
      product.status.colors
    );

    return Object.keys(data).map((category, index) => {
      const categoryGroups = find(categories, { category });
      const status = get(product.status, 'options.ignoreProgress', false)
        ? null
        : categoryGroups.status;
      const percentage = get(product.status, 'options.ignoreProgress', false)
        ? null
        : this.setCompletionPercentage(data[category], status);
      const additionalFields = product.status.additionalFields
        ? product.status.additionalFields
        : null;
      const count = get(product.status, 'options.showCategoryCount', false)
        ? true
        : false;
      const props = {
        key: index,
        items: data[category],
        category,
        status,
        percentage,
        labels,
        additionalFields,
        count,
      };

      return <StatusCard {...props} />;
    });
  }

  render() {
    const { data, categories, hasScrollbar } = this.state;
    const { title, match, updated } = this.props;

    if (data === null || categories === null) {
      return <Loader />;
    }

    return (
      <DocumentTitle title={setTitle({ match, title })}>
        <Branded updated={updated}>
          <Categories
            ref={this.setCategoriesRef}
            count={categories.length}
            hasScrollbar={hasScrollbar}
          >
            {this.renderCategories()}
          </Categories>
        </Branded>
      </DocumentTitle>
    );
  }
}

export default withSpreadsheet(Status);
