import React from 'react';

import Container from './styled/Container';
import Icon from './styled/Icon';
import Text from './styled/Text';

const Loader = () => (
  <Container>
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width="20"
      height="20"
    >
      <g fill="#111">
        <path d="M29.915 23.897a15.906 15.906 0 0 1-7.01 6.537 2 2 0 1 1-1.723-3.61 11.923 11.923 0 0 0 5.254-4.901c3.266-5.755 1.241-13.094-4.513-16.36s-13.094-1.24-16.36 4.514-1.24 13.094 4.514 16.36c1.286.73 2.67 1.21 4.115 1.43a2 2 0 1 1-.6 3.954 15.929 15.929 0 0 1-5.49-1.906C.43 25.561-2.27 15.775 2.086 8.103S16.225-2.27 23.897 2.085s10.373 14.14 6.018 21.812z" />
      </g>
    </Icon>
    <Text>Loading...</Text>
  </Container>
);

export default Loader;
