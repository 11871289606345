import styled from 'styled-components';

import theme from '../../../config/theme';

const Container = styled.div`
  display: flex;
  margin: ${theme.spacing.base};
`;

export default Container;
