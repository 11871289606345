import styled from 'styled-components';

import theme from '../../../config/theme';

const Container = styled.section`
  background-color: ${theme.colors.white};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
`;

export default Container;
