import styled from 'styled-components';

import theme from '../../../config/theme';

const ContentContainer = styled.div`
  background-color: ${theme.colors.white};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  max-width: 600px;
`;

export default ContentContainer;
