import styled from 'styled-components';

import theme from '../../../config/theme';

const Label = styled.p`
  background-color: ${theme.colors.black};
  color: ${theme.colors.white};
  font-size: ${theme.fonts.sizes.xs};
  font-weight: bold;
  left: 0;
  letter-spacing: 1px;
  margin-bottom: ${theme.spacing.xs};
  padding: ${theme.spacing.xs} ${theme.spacing.s};
  position: absolute;
  text-transform: uppercase;
  top: 0;

  @media screen and (min-width: 600px) {
    left: -${theme.spacing.s};
    top: ${theme.spacing.s};
  }
`;

export default Label;
