import styled from 'styled-components';
import { Link } from 'react-router-dom';

import theme from '../../../config/theme';

const Back = styled(Link)`
  color: ${theme.colors.mediumGray};
  font-size: 50px;
  left: ${theme.spacing.base};
  position: absolute;
  text-decoration: none;
  top: 46%;
  transform: translateY(-50%);
`;

export default Back;
