import styled from 'styled-components';

import theme from '../../../config/theme';

const Clear = styled.button`
  background-color: transparent;
  border: 0;
  color: ${theme.colors.orange};
  cursor: pointer;
  font-size: ${theme.fonts.sizes.s};
  font-weight: bold;
  text-decoration: underline;
`;

export default Clear;
