import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { getProduct } from '../../config/products';
import { formatLogoFilename } from '../../lib/utils';

import Back from './styled/Back';
import Bar from './styled/Bar';
import Container from './styled/Container';
import Logo from './styled/Logo';
import Updated from './styled/Updated';

class Branded extends Component {
  getProductLogo() {
    const { match } = this.props;
    const product = getProduct(match.params.id);

    if (product.status.options && product.status.options.useInternalLogo)
      return `/logos/internal.svg`;

    const filename = formatLogoFilename(product.client);

    return `/logos/${filename}.svg`;
  }

  getLogoHeight() {
    const { match } = this.props;
    const product = getProduct(match.params.id);

    if (product.status.options && product.status.options.logoHeight) {
      return product.status.options.logoHeight;
    }

    return '40';
  }

  renderHeader() {
    const { match, updated } = this.props;

    return (
      <Bar>
        <Back to={`/${match.params.id}`}>&laquo;</Back>
        <Logo
          src={this.getProductLogo()}
          alt=""
          height={this.getLogoHeight()}
        />
        {updated ? <Updated>Updated: {updated}</Updated> : null}
      </Bar>
    );
  }

  render() {
    return (
      <Container {...this.props}>
        {this.renderHeader()}
        {this.props.children}
      </Container>
    );
  }
}

export default withRouter(Branded);
