import theme from '../config/theme';

export const getStatusColor = (status, labels, fallback) => {
  if (status === null) return theme.colors.orange;

  const matchingLabel = Object.keys(labels).find(label => {
    if (Array.isArray(labels[label])) {
      return labels[label].includes(status);
    }

    return labels[label] === status;
  });

  if (matchingLabel === undefined) {
    return fallback;
  }

  return theme.statusColors[matchingLabel];
};

export const listReset = () =>
  `
    list-style-type: none;
    margin: 0;
    padding: 0;
  `;
