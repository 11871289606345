import styled from 'styled-components';

import theme from '../../../config/theme';

const Container = styled.div`
  margin-bottom: ${theme.spacing.l};
  margin-top: ${theme.spacing.l};
  position: relative;
`;

export default Container;
