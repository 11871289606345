const theme = {
  colors: {
    black: '#434141',
    yellow: '#ffc425',
    green: '#90c251',
    mediumGray: '#b7b7b7',
    lightGray: '#f6f6f6',
    white: 'white',
    red: '#f17374',
    orange: '#e27433',
  },
  statusColors: {
    green: '#90c251',
    yellow: '#ffc425',
    red: '#f17374',
    neutral: '#b7b7b7',
  },
  fonts: {
    family: "'Work Sans', serif",
    sizes: {
      base: '16px',
      xs: '10px',
      s: '14px',
      l: '20px',
    },
  },
  spacing: {
    base: '20px',
    xs: '5px',
    s: '10px',
    l: '40px',
    xl: '80px',
  },
  breakpoints: {
    m: '740px',
    l: '1100px',
    xl: '1400px',
  },
  radius: {
    base: '4px',
  },
};

export default theme;
