import styled from 'styled-components';

import theme from '../../../config/theme';

const status = ({ skill, skills, categories }) => {
  if (!skills) {
    return `
      background-color: ${theme.colors.orange};
    `;
  }

  const five = skills[categories[1].key].includes(skill);
  const four = skills[categories[2].key].includes(skill);
  const three = skills[categories[3].key].includes(skill);
  const two = skills[categories[4].key].includes(skill);
  const one = skills[categories[5].key].includes(skill);

  if (five || four) {
    return `
      background-color: ${theme.colors.green};
    `;
  }

  if (three) {
    return `
      background-color: ${theme.colors.yellow};
    `;
  }

  if (two || one) {
    return `
      background-color: ${theme.colors.red};
    `;
  }

  return `
    background-color: ${theme.colors.mediumGray};
  `;
};

const inactive = ({ isInactive }) => {
  if (isInactive) {
    return `
      background-color: ${theme.colors.mediumGray};
      opacity: 0.5;
    `;
  }
};

const SkillButton = styled.button`
  border: 0;
  border-radius: 100px;
  color: ${theme.colors.white};
  cursor: pointer;
  display: block;
  font-family: ${theme.fonts.family};
  font-size: ${theme.fonts.sizes.base};
  font-weight: bold;
  padding: ${theme.spacing.s} ${theme.spacing.base};
  transition: 0.3s ease-in-out;
  width: 100%;

  &:focus {
    outline: none;
  }

  ${status};
  ${inactive};
`;

export default SkillButton;
