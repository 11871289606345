import { getProduct } from '../config/products';

export const formatLogoFilename = string =>
  string
    .toLowerCase()
    .split(' ')
    .join('-');

export const setTitle = ({ match, title }) => {
  const product = getProduct(match.params.id);

  if (product.project === '') {
    return `${product.client} / ${title}`;
  }

  return `${product.client} / ${product.project} / ${title}`;
};
