import styled from 'styled-components';

import theme from '../../../config/theme';
import { getStatusColor } from '../../../lib/styleUtils';

const showCategoryCount = props => {
  if (props.count) {
    return `
      &::after {
        background-color: rgba(255, 255, 255, 0.2);
        bottom: 0;
        color: ${theme.colors.white};
        content: "${props.count}";
        font-size: ${theme.fonts.sizes.l};
        font-weight: bold;
        line-height: 2.1; // FIXME: Magic number!
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        width: 50px;
      }
    `;
  }

  return null;
}

const Header = styled.section`
  background-color: ${theme.colors.black};
  padding: ${theme.spacing.s};
  position: relative;

  &::before {
    background-color: ${props =>
      props.ignoreProgress
        ? theme.statusColors.neutral
        : getStatusColor(
            props.status,
            props.labels,
            theme.statusColors.neutral
          )};
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 50px;
  }

  ${props => showCategoryCount(props)};
`;

export default Header;
