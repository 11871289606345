import { createGlobalStyle } from 'styled-components';

import theme from '../../config/theme';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    background-color: ${theme.colors.lightGray};
    color: ${theme.colors.black};
    font-family: ${theme.fonts.family};
    font-size: ${theme.fonts.sizes.base};
    line-height: 1.5;
  }

  ::selection {
    background-color: #f9e3d6;
  }

  ::-moz-selection {
    background-color: #f9e3d6;
  }

  a {
    color: ${theme.colors.orange};
    text-decoration: underline;

    &:active,
    &:focus,
    &:hover {
      color: ${theme.colors.orange};
      text-decoration: none;
    }
  }

  img {
    display: block;
    max-width: 100%;
  }

  ol {
    margin-left: ${theme.spacing.base};
  }
`;

export default GlobalStyle;
