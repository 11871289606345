import React from 'react';

import Container from './styled/Container';
import Meta from './styled/Meta';
import MetaItem from './styled/MetaItem';
import Title from './styled/Title';

const hasAdditionalField = field => field !== '' && field !== undefined;

const renderAdditionalFields = (fields, rest) =>
  fields.map(
    (field, index) =>
      hasAdditionalField(rest[field]) && (
        <MetaItem key={index}>{rest[field]}</MetaItem>
      )
  );

const StatusItem = ({
  status,
  item,
  labels,
  additionalFields,
  totalItems,
  ...rest
}) => (
  <Container status={status} labels={labels} totalItems={totalItems}>
    <Title title={status}>{item}</Title>
    {additionalFields && (
      <Meta>{renderAdditionalFields(additionalFields, rest)}</Meta>
    )}
  </Container>
);

export default StatusItem;
