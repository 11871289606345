import styled from 'styled-components';

import theme from '../../../config/theme';

const Container = styled.div`
  @media screen and (min-width: ${theme.breakpoints.m}) {
    height: 100vh;
    overflow-y: ${props => (props.verticalScroll ? 'visible' : 'hidden')};
  }
`;

export default Container;
