import styled from 'styled-components';

import theme from '../../../config/theme';

const Footer = styled.footer`
  background-color: ${theme.colors.black};
  padding: ${theme.spacing.s};
  position: relative;
`;

export default Footer;
