import styled from 'styled-components';

import theme from '../../../config/theme';

const Bar = styled.div`
  align-items: center;
  background-color: ${theme.colors.white};
  display: flex;
  justify-content: center;
  margin-bottom: ${theme.spacing.s};
  min-height: 85px;
  padding: ${theme.spacing.s};
  position: relative;
`;

export default Bar;
