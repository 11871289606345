import styled, { keyframes } from 'styled-components';

import theme from '../../../config/theme';
import { getStatusColor } from '../../../lib/styleUtils';

const progress = keyframes`
  from {
    width: 0;
  }
`;

const ProgressBar = styled.div`
  animation: ${progress} 1s 0.2s 1;
  animation-fill-mode: forwards;
  background-color: ${props =>
    getStatusColor(props.status, props.labels, theme.statusColors.neutral)};
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 0;
  z-index: 1;
`;

export default ProgressBar;
